/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Directive } from '@angular/core';

@Directive({
  selector: '[mspotMenuItem]',
  host: {
    class:
      'flex items-center text-left rounded-lg w-full px-3 py-2.5 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900',
    tabindex: '-1',
  },
})
export class MenuItemDirective {}
